/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Cookies from 'universal-cookie'
import SvgIcon from './SvgIcon'
import { AppContext } from '../store/context'
import { ActionTypes } from '../store/actions'
import SmartSelectInstitution from './SmartSelectInstitution'
import SmartSelectOrganization from './SmartSelectOrganization'
import InstitutionEntity from '../utils/InstitutionEntity'
import PERIMETER_MODE from '../utils/PERIMETER_MODE'
import BandeauPerimetre from './molecules/BandeauPerimetre'
import { truncateString } from '../utils/ts-utils'

function PerimeterFilter() {
  const { t } = useTranslation()
  const {
    state: {
      organization,
      institution,
      impersonnation: { isImpersonnation },
      ui: { perimeterFilterMode },
    },
    dispatch,
  } = useContext(AppContext)

  const NEW_PERIMETER_FILTER = Boolean(
    parseInt(process?.env?.REACT_APP_NEW_PERIMETER_FILTER || '1', 10)
  )

  // STATE
  const [impersonnationClass, setImpersonnationClass] = useState('')
  const [filterResetClass, setFilterResetClass] = useState(
    'perimeter-filter__reset'
  )
  const [displayButtonClass, setDisplayButtonClass] = useState(
    'perimeter-filter__display-button'
  )
  const [forceReset, setForceReset] = useState(false)
  const [forceUnfold, setForceUnfold] = useState(false)
  const [organizationSetted, setOrganizationSetted] = useState(
    organization.items.length > 0
  )
  const [initialValue, setInitialValue] = useState<InstitutionEntity[]>([])

  const [organisationLibelle, setOrganisationLibelle] = useState(
    'Organisations'
  )
  const [institutionLibelle, setInstitutionLibelle] = useState('Établissements')

  const [disabledSearch, setDisabledSearch] = useState<boolean>(false)
  // HANDLER
  const switchMode = () => {
    dispatch({
      type: ActionTypes.SWITCH_PERIMETER_FILTER_MODE,
      payload: null,
    })
  }
  useEffect(() => {
    setForceReset(!forceReset)
    setOrganizationSetted(false)
    if (isImpersonnation) {
      setImpersonnationClass('impersonate')
      setFilterResetClass('perimeter-filter__reset-impersonate')
      setDisplayButtonClass('perimeter-filter__display-button-impersonate')
    } else {
      setImpersonnationClass('')
      setFilterResetClass('perimeter-filter__reset')
      setDisplayButtonClass('perimeter-filter__display-button')
    }
  }, [isImpersonnation])

  useEffect(() => {
    if (perimeterFilterMode === PERIMETER_MODE.DECREASED_MODE) {
      if (organization.items.length > 1) {
        setOrganisationLibelle(
          `${organization.items.length} ${t(
            'perimeterfilter.label.orgaSelected'
          )}`
        )
      } else if (organization.items.length === 1) {
        setOrganisationLibelle(
          `${t('perimeterfilter.label.oneOrgaSelected')} ${
            organization.items[0].name
          }`
        )
      } else {
        setOrganisationLibelle(t('perimeterfilter.label.organizations'))
      }

      if (institution.items.length > 1) {
        setInstitutionLibelle(
          `${institution.items.length} ${t(
            'perimeterfilter.label.institutionsSelected'
          )}`
        )
      } else if (institution.items.length === 1) {
        setInstitutionLibelle(
          `${t('perimeterfilter.label.oneInstitutionSelected')} ${
            institution.items[0].name
          }`
        )
      } else {
        setInstitutionLibelle(t('perimeterfilter.label.institutions'))
      }
    } else {
      setInstitutionLibelle(t('perimeterfilter.label.institutions'))
      setOrganisationLibelle(t('perimeterfilter.label.organizations'))
    }
  }, [organization, institution, perimeterFilterMode])

  const resetFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    // clear cookies
    const cookies = new Cookies()
    cookies.remove('chosenInstitution', { path: '/' })
    cookies.remove('chosenOrganization', { path: '/' })
    dispatch({
      type: ActionTypes.RESET_PERIMETER_FILTER,
    })
    setForceReset(!forceReset)
    setInitialValue([])
    setOrganizationSetted(false)
  }

  const handleOrganizationsSetted = () => {
    setForceUnfold(!forceUnfold)
    setOrganizationSetted(true)
  }

  return (
    <>
      {NEW_PERIMETER_FILTER ? (
        <>
          <BandeauPerimetre
            resetFilter={resetFilter}
            filterResetClass={filterResetClass}
            classe={impersonnationClass}
          >
            <div
              className="perimeter-filter__organizations"
              id="organisationId"
            >
              <div>{truncateString(organisationLibelle, 50)}</div>
              <SmartSelectOrganization
                forceReset={forceReset}
                setForceReset={setForceReset}
                onOrganizationsSetted={handleOrganizationsSetted}
                onSearchInstitutionsValidate={setInitialValue}
                onDisabledSearchInstitution={setDisabledSearch}
                searchWithSetInstitutions={true}
                selectedOrganizations={organization.items}
                isBandeau={true}
              />
            </div>
            <div className="perimeter-filter__institutions" id="compagnyId">
              <div>{truncateString(institutionLibelle, 50)}</div>
              <SmartSelectInstitution
                organizationSetted={organizationSetted}
                initalValues={initialValue}
                forceReset={forceReset}
                setForceReset={setForceReset}
                forceUnfold={forceUnfold}
                selectedInstitutions={institution.items}
                disabledSearch={disabledSearch}
                isBandeau={true}
              />
            </div>
          </BandeauPerimetre>
        </>
      ) : (
        <>
          <div
            className={`perimeter-filter ${impersonnationClass} ${perimeterFilterMode}`}
          >
            <div className="perimeter-filter__content">
              <div className="perimeter-filter__title">
                {t('perimeterfilter.label.title')}
              </div>
              <div
                className="perimeter-filter__organizations"
                id="organisationId"
              >
                <div>{organisationLibelle}</div>
                <SmartSelectOrganization
                  forceReset={forceReset}
                  setForceReset={setForceReset}
                  onOrganizationsSetted={handleOrganizationsSetted}
                  onSearchInstitutionsValidate={setInitialValue}
                  onDisabledSearchInstitution={setDisabledSearch}
                  searchWithSetInstitutions={true}
                  selectedOrganizations={organization.items}
                  isBandeau={false}
                />
              </div>
              <div className="perimeter-filter__institutions" id="compagnyId">
                <div>{institutionLibelle}</div>
                <SmartSelectInstitution
                  organizationSetted={organizationSetted}
                  initalValues={initialValue}
                  forceReset={forceReset}
                  setForceReset={setForceReset}
                  forceUnfold={forceUnfold}
                  selectedInstitutions={institution.items}
                  disabledSearch={disabledSearch}
                  isBandeau={false}
                />
              </div>
              <button
                type="button"
                className={`${filterResetClass}`}
                onClick={resetFilter}
              >
                <span>{t('perimeterfilter.link.reset')}</span>
                <SvgIcon
                  name="iconRight"
                  className={`${filterResetClass}-icon`}
                />
              </button>
            </div>
          </div>
          <div className={`perimeter-filter__display ${perimeterFilterMode}`}>
            <div
              className="perimeter-filter__display-right"
              onClick={switchMode}
            >
              <div className={`${displayButtonClass} perimeter-filter__plus`}>
                {t('perimeterfilter.label.increase')}
                <SvgIcon name="plus" className="perimeter-filter__icon" />
              </div>
              <div className={`${displayButtonClass} perimeter-filter__minus`}>
                {t('perimeterfilter.label.decrease')}
                <SvgIcon name="minus" className="perimeter-filter__icon" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default PerimeterFilter
