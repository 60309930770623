/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useHistory, Route } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import ROLES from '../utils/ROLES'
import { DecodedToken } from '../services/user-services'
import ROUTES from '../utils/ROUTES'
import MAINTENANCE_TYPE from '../utils/maintenance-utils'
import { hasAccessTo, roleNamesToNumber } from '../services/roles-services'
import { AppContext } from '../store/context'

interface CustomRouteProps {
  children?: React.ReactNode
  path: string | string[]
  exact: boolean
  condition: ROLES[]
  personnalizedCondition?: boolean
  partialMaintenance?: PartialMaintenance
}

interface PartialMaintenance {
  type: MAINTENANCE_TYPE.ALL | MAINTENANCE_TYPE.DEMANDS
}

const CustomRoute = (props: CustomRouteProps) => {
  const history = useHistory()

  const {
    state: { user },
  } = React.useContext(AppContext)

  const [returnedRoute, setReturnedRoute] = useState(<Route {...props} />)

  const { condition, path, personnalizedCondition, partialMaintenance } = props

  React.useEffect(() => {
    let userRoles = user.roleNames

    const impersonateMode = JSON.parse(
      sessionStorage.getItem('impersonateMode') || '{}'
    )
    // Cas de refresh page en mode impersonation : récuperation de roles de l'admin
    if (impersonateMode && path === ROUTES.UserImpersonation) {
      const accessToken = localStorage.getItem('access_token')
      const decodedToken: DecodedToken = jwt_decode(accessToken || '')
      userRoles = decodedToken.roles.map((e) => e.name)
    }

    if (userRoles.length === 0) {
      history.push(ROUTES.CheckEmail)
    } else if (!hasAccessTo(condition, roleNamesToNumber(userRoles))) {
      history.push(ROUTES.DeniedAccess)
    }

    if (personnalizedCondition) {
      history.push(ROUTES.DeniedAccess)
    }

    if (partialMaintenance?.type) {
      if (
        localStorage.getItem(`maintenanceMode${partialMaintenance?.type}`) ===
        'true'
      )
        history.push(ROUTES.MaintenanceError, {
          type: partialMaintenance.type,
        })
    }

    setReturnedRoute(<Route {...props} />)
  }, [path])

  return returnedRoute
}

export default CustomRoute
