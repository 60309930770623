/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
import React, { useState, useRef, useContext, useEffect } from 'react'
import { useHistory, useLocation, Prompt } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import { useIdleTimer } from 'react-idle-timer'
import axios from 'axios'
import { AppContext } from '../store/context'
import { ActionTypes } from '../store/actions'
import { logout } from '../services/connexion-services'
import ROUTES, { EXCLUDED_ROUTES } from '../utils/ROUTES'
import SessionExpirationModal from './SessionExpirationModal'
import interceptorImpersonnation, {
  checkTokenExpiry,
  interceptorNavigation,
} from '../services/session-services'

interface Props {
  showingCreationModal: boolean
}

const SessionAuthenticated: React.FC<Props> = ({
  showingCreationModal,
}: Props) => {
  const {
    state: {
      impersonnation: { emailImpersonated },
    },
    state: contextState,
    dispatch,
  } = useContext(AppContext)
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  const sessionTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  )

  const [noActivity, setNoActivity] = useState<boolean>(false)
  const [refreshTime, setRefreshTime] = useState<Date>(new Date())

  const intervalRef = useRef<
    ReturnType<typeof setInterval> | string | number | undefined
  >(undefined)

  const { instance } = useMsal()

  const channel = new BroadcastChannel('PCE-CHANNEL')

  useEffect(() => {
    channel.onmessage = async (msg) => {
      switch (msg.data) {
        case 'ACTIVE_SESSION': // reactiver les autres onglets
          resetSessionTimers()
          break
        case 'EXPIRED_SESSION': // expirer les autres onglets
          handleSessionExpired()
          history.push(ROUTES.SessionExpired)
          break
        case 'CLOSE_SESSION_MODAL': // fermer la popin de garder ma session des autres onglets
          setNoActivity(false)
          break
        case 'DISCONNECT': // déconnecter les autres onglets
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
          sessionStorage.removeItem('impersonateMode')
          sessionStorage.removeItem('impersonation_token')
          sessionStorage.removeItem('refresh_token')
          // quitter l'impersonnation en cas de déconnexion
          dispatch({
            type: ActionTypes.SET_IS_IMPERSONNATION,
            payload: {
              isImpersonnation: false,
            },
          })
          dispatch({
            type: ActionTypes.SET_USERNAME_IMPERSONNATION,
            payload: {
              nameImpersonnated: '',
              accessDemands: false,
              emailImpersonated: '',
            },
          })

          if (location.pathname === ROUTES.CreateDemand) {
            document.location.href = ROUTES.CheckEmail
          }
          history.push(ROUTES.CheckEmail)
          break
        case 'CONNECT': // connecter les autres onglets
          const connecting = sessionStorage.getItem('CONNECTING')
          // attendre que le login mis à jour l'access token dans localsatorage
          await new Promise((resolve) => setTimeout(resolve, 1000))
          if (connecting !== 'true') window.location.reload()
          else sessionStorage.removeItem('CONNECTING')

          break
        default:
          break
      }
    }
    sessionTimeout.current = sessionExpirationTimeout()

    return () => {
      clearTimeout(sessionTimeout.current)
    }
  }, [location])

  // Vérifier l'expiration du token au chargement de la page et toutes les 5 minutes
  useEffect(() => {
    checkTokenExpiry(emailImpersonated || '')
    intervalRef.current = setInterval(
      () => checkTokenExpiry(emailImpersonated || ''),
      5 * 60 * 1000
    )

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [emailImpersonated])

  // initialisation d'intercepteur
  const axiosInterceptor = axios.interceptors.request.use(
    async (config) => {
      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  useEffect(() => {
    if (location.pathname && EXCLUDED_ROUTES.indexOf(location.pathname) > -1) {
      return
    }
    // nettoyer les interceptors en cas de changement de emailImpersonated ou route
    axios.interceptors.request.clear()

    if (emailImpersonated !== '') {
      // en cas d'impersonnation
      interceptorImpersonnation(axiosInterceptor)
    } else {
      // en cas de navigation normale
      interceptorNavigation(axiosInterceptor)
    }
  }, [emailImpersonated, location])

  const sessionExpirationTimeout = (): ReturnType<typeof setTimeout> => {
    if (sessionTimeout?.current) {
      clearTimeout(sessionTimeout?.current)
    }

    const timeoutId = setTimeout(() => {
      handleSessionExpired()
      history.push(ROUTES.SessionExpired)
      channel.postMessage('EXPIRED_SESSION')
    }, Number(process.env.REACT_APP_SESSION_TIMEOUT) * 60 * 1000)

    return timeoutId
  }

  const handleActivityAction = () => {
    if (!noActivity) {
      channel.postMessage('ACTIVE_SESSION')
      resetSessionTimers()
    }
  }

  const handleNoActivity = () => {
    setRefreshTime(
      new Date(
        new Date().getTime() +
          (Number(process.env.REACT_APP_SESSION_TIMEOUT) -
            Number(process.env.REACT_APP_SESSION_REMINDER)) *
            60 *
            1000
      )
    )
    setNoActivity(true)
  }

  const handleSessionExpired = () => {
    const refreshToken = localStorage.getItem('refresh_token')

    if (!refreshToken) {
      return
    }

    if (contextState.user.isInternal) {
      instance.logout()
    }

    dispatch({
      type: ActionTypes.SET_TOKEN_INFO,
      payload: {
        username: '',
        userId: '',
        isInternal: false,
        organizations: [],
        roleNames: [],
        firstname: '',
        accessDemands: false,
        accessDocs: false,
        idContactWdh: '',
      },
    })
    // reset all roles
    dispatch({
      type: ActionTypes.SET_ALL_ROLES,
      payload: {
        allRoles: [],
      },
    })
    // reset Organisations and Institutions
    dispatch({
      type: ActionTypes.SET_ORGANIZATION_LIST,
      payload: {
        organizationList: [],
      },
    })
    dispatch({
      type: ActionTypes.SET_INSTITUTION_LIST,
      payload: {
        institutionList: [],
      },
    })
    // to clear Impersonnation value
    dispatch({
      type: ActionTypes.SET_IS_IMPERSONNATION,
      payload: {
        isImpersonnation: false,
      },
    })
    // clear the cookies and logout
    logout()
  }

  const { reset: resetRefresh } = useIdleTimer({
    onAction: handleActivityAction,
    onIdle: handleNoActivity,
    timeout: Number(process.env.REACT_APP_SESSION_REMINDER) * 60 * 1000,
    debounce: 500,
  })

  const resetSessionTimers = () => {
    clearTimeout(sessionTimeout.current)
    sessionTimeout.current = sessionExpirationTimeout()

    resetRefresh()
  }

  const handleCloseSessionModal = async () => {
    try {
      channel.postMessage('CLOSE_SESSION_MODAL')

      resetSessionTimers()
      setNoActivity(false)
    } catch (err) {
      throw Error('Error while login to resume session')
    }
  }

  return (
    <>
      {noActivity && (
        <SessionExpirationModal
          showModal={noActivity}
          handleCloseSessionModal={handleCloseSessionModal}
          endTime={refreshTime}
        />
      )}
      <Prompt
        when={
          !noActivity &&
          window.location.href.includes(ROUTES.CreateDemand) &&
          !showingCreationModal
        }
        message={() =>
          t('myDemandsManagement.popin.exitPageOrTabOrWindow') as string
        }
      />
    </>
  )
}

export default SessionAuthenticated
