/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { CancelTokenSource } from 'axios'
import Cookies from 'universal-cookie'
import qs from 'qs'
import { DecodedToken, UserData } from './user-services'
// eslint-disable-next-line
import jwt_decode from 'jwt-decode'
import { isCancel } from './billing-services'

export enum ErrorMessageLinkExpired {
  CREATION_LINK_EXPIRED = 'La création du mot de passe doit se faire dans les 7 jours suivants la réception de l’email, veuillez renouveler la demande en cliquant ici.',
  MODIFICATION_LINK_EXPIRED = 'La réinitialisation doit se faire dans les 3 heures après réception de l’email, veuillez renouveler la demande en cliquant ici.',
}

export enum GlobalErrorMessage {
  UNAVAILABLE_EMAIL = 'Email not available',
}

interface FixConnexionResponse {
  success: boolean
  status: string
  message: string
  type: string
}

interface UserCategorie {
  isInternal: boolean
  byPassRecaptch: boolean
}

interface UserFullResult {
  UserData: UserData
  newToken: string
}

const COOKIE_CURRENT_USER_ROLE_NAMES = 'current_user_roles'
let cancelToken: CancelTokenSource
// to use in the request header
async function loginGlobal(loginParams: any): Promise<UserFullResult> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/login`
  cancelToken = axios.CancelToken.source()
  let accessToken = null

  const cookies = new Cookies()

  try {
    const resp = await axios({
      method: 'POST',
      url,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      data: qs.stringify({ ...loginParams }),
      cancelToken: cancelToken.token,
    })

    // ACCESS TOKEN
    accessToken = resp?.data?.access_token
    if (loginParams.grant_type !== 'refresh_token')
      axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` }

    // REFRESH TOKEN
    const refreshTokentmp = resp?.data?.refresh_token
    // Mise en place du refresh_token dans localStorage
    localStorage.setItem('access_token', accessToken)
    localStorage.setItem('refresh_token', refreshTokentmp)
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve(err)
    }
    if (
      loginParams.grant_type === 'suez_grant' &&
      err.response.data.error === 'invalid_grant'
    )
      throw new Error(`Deactivated internal user`)
    else if (err.response.data.message === 'Password expired.')
      throw new Error(`Password expired`)
    else throw new Error(`Authentication failed.`)
  }

  const decodedToken: DecodedToken = jwt_decode(accessToken)

  if (
    !decodedToken.username &&
    !decodedToken.user_id &&
    !decodedToken.organizations
  ) {
    return Promise.resolve({
      UserData: {
        username: '',
        userId: '',
        organizations: [],
        roleNames: [],
        firstname: '',
        accessDemands: false,
        accessDocs: false,
        isInternal: true,
        idContactWdh: '',
      },
      newToken: '',
    })
  }

  const roles = decodedToken.roles.map((e) => e.name)

  // Used to handle redirections when accessing pages that require no authentification (login, checkingEmailForm...).
  // To enhance: use Redux to handle the state of the app from the begining (AppContainer) => Requires a lot of effort at this stage of the project.
  cookies.set(COOKIE_CURRENT_USER_ROLE_NAMES, JSON.stringify(roles), {
    path: '/',
    secure: true,
  })

  return {
    UserData: {
      username: decodedToken.username,
      userId: decodedToken.user_id,
      isInternal: Boolean(decodedToken.isInternal),
      organizations: decodedToken.organizations.map((org) => org.id),
      roleNames: roles,
      firstname: decodedToken.first_name,
      accessDemands: Boolean(decodedToken.access_demands),
      accessDocs: Boolean(decodedToken.access_docs),
      idContactWdh: decodedToken.id_contact_wdh,
    },
    newToken: `Bearer ${accessToken}`,
  }
}

async function login(loginParams: any): Promise<UserData> {
  const fullUserData = await loginGlobal(loginParams)
  return fullUserData.UserData
}

export async function fullLoginWithRefreshToken(
  refreshtoken: string
): Promise<UserFullResult> {
  return loginGlobal({
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
    grant_type: 'refresh_token',
    refresh_token: refreshtoken,
  })
}
export async function loginWithRefreshToken(
  refreshtoken: string
): Promise<UserData> {
  return login({
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
    grant_type: 'refresh_token',
    refresh_token: refreshtoken,
  })
}

export async function loginWithPassword(
  email: string,
  password: string,
  recaptchaResponse?: string | null
): Promise<UserData> {
  return login({
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
    grant_type: 'password',
    username: email,
    password,
    'g-recaptcha-response': recaptchaResponse,
  })
}

export async function loginWithAADToken(
  email: string,
  accessToken: string
): Promise<UserData> {
  return login({
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
    grant_type: 'suez_grant',
    username: email,
    token: accessToken,
  })
}

export async function logout() {
  const cookies = new Cookies()
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  sessionStorage.removeItem('impersonation_token')
  sessionStorage.removeItem('refresh_token')
  sessionStorage.removeItem('ImpersonateMode')
  cookies.remove('chosenInstitution', { path: '/' })
  cookies.remove('chosenOrganization', { path: '/' })
  cookies.remove(COOKIE_CURRENT_USER_ROLE_NAMES)
  cookies.remove('side_messages_sent')
  cookies.remove('commIsShowed')

  // [SESS_01] - CLEAR TOKEN SERVER SIDE
  const url = `${process.env.REACT_APP_BASE_API_URL}/logout`
  try {
    await axios({
      method: 'POST',
      url,
    })
  } catch (err) {
    throw new Error(`Erreur lors de la déconnexion`)
  }
}

export default async function checkLinkValidity(
  username: string,
  token: string
): Promise<boolean> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/check-link-validity`
  let status = false

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        username,
        token,
      },
    })
    status = resp?.data?.success
  } catch (err) {
    console.error("Erreur lors de la demande d'un nouveau mot de passe")
    return false
  }

  return status
}

export async function newPassword(email: string): Promise<boolean> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/reset-password/request`

  let status = false

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        username: email,
      },
    })
    status = resp?.data?.success
  } catch (err) {
    if (err?.response?.data?.violations) {
      throw new Error(err.response.data.violations[0].message)
    }
    throw new Error("Erreur lors de la demande d'un nouveau mot de passe")
  }

  return status
}

export async function modifyEmail(
  email: string,
  newEmail: string,
  password: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/reset-email/request`
  let status = 400

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        username: email,
        newUsername: newEmail,
        password,
      },
    })
    status = resp?.status
  } catch (err) {
    return err?.response?.status
  }

  return status
}

export async function fixConnexion(
  email: string
): Promise<FixConnexionResponse> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/fix-connexion`

  let { success, message, type, status } = {
    success: false,
    message: '',
    type: '',
    status: '',
  }

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        email,
      },
    })

    success = resp?.data?.success
    message = resp?.data?.message
    type = resp?.data?.type
    status = resp?.data?.status
  } catch (err) {
    if (err?.response?.data?.violations) {
      throw new Error(err.response.data.violations[0].message)
    }
    throw new Error("Erreur lors de la demande d'un nouveau mot de passe")
  }

  return { success, message, type, status }
}

export async function reset(
  email: string,
  password: string,
  token: string,
  type: string | undefined
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/reset-password/apply`

  let status = 400

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        username: email,
        password,
        token,
        gdprConsentGiven: true,
      },
    })
    status = resp?.status
  } catch (err) {
    if (
      err?.response &&
      err.response?.status === 401 &&
      err.response?.data?.error === 'expired_token' &&
      err.response?.data?.error_description === 'Token expired' &&
      err.response?.data?.message === 'Token expired'
    ) {
      if (type !== undefined) {
        throw new Error(ErrorMessageLinkExpired.CREATION_LINK_EXPIRED)
      }
      throw new Error(ErrorMessageLinkExpired.MODIFICATION_LINK_EXPIRED)
    } else if (err?.response?.data?.violations) {
      throw new Error(err.response.data.violations[0].message)
    } else {
      throw new Error("Une erreur s'est produite, veuillez recommencer.")
    }
  }

  return status
}

export async function resetEmail(
  email: string,
  token: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/reset-email/apply`

  let status = 400

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        username: email,
        token,
      },
    })
    status = resp?.status
  } catch (err) {
    if (
      err?.response &&
      err.response?.status === 401 &&
      err.response?.data?.error === 'expired_token' &&
      err.response?.data?.error_description === 'Token expired' &&
      err.response?.data?.message === 'Token expired'
    ) {
      throw new Error(ErrorMessageLinkExpired.MODIFICATION_LINK_EXPIRED)
    } else if (
      err?.response &&
      err.response?.status === 401 &&
      err.response?.data?.message === GlobalErrorMessage.UNAVAILABLE_EMAIL
    ) {
      throw new Error(GlobalErrorMessage.UNAVAILABLE_EMAIL)
    } else if (err?.response?.data?.violations) {
      throw new Error(err.response.data.violations[0].message)
    } else {
      throw new Error("Une erreur s'est produite, veuillez recommencer.")
    }
  }

  return status
}

export async function adminResetUserPassword(email: string): Promise<boolean> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/admin-reset-user-password/request`

  let status = false

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        username: email,
      },
    })
    status = resp?.data?.success
  } catch (err) {
    if (err?.response?.data?.violations) {
      throw new Error(err.response.data.violations[0].message)
    }
    throw new Error("Erreur lors de la demande d'un nouveau mot de passe")
  }

  return status
}

export async function reinitializeUserPassword(
  userId: string,
  newPasswordExpirationDate: Date
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${userId}`
  let status = 400

  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: { passwordExpirationDate: newPasswordExpirationDate },
    })
    status = resp?.status
  } catch (err) {
    if (err?.response?.data?.violations) {
      throw new Error(err.response.data.violations[0].message)
    }
    throw new Error('Erreur lors de la réinitialisation du mot de passe')
  }

  return status
}

export async function patchUserPassword(
  newpassword: string,
  userId: string,
  type: string | undefined
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${userId}`
  let status = 400

  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: {
        password: newpassword,
      },
    })
    status = resp?.status
  } catch (err) {
    if (
      err?.response &&
      err.response?.status === 401 &&
      err.response?.data?.error === 'expired_token' &&
      err.response?.data?.error_description === 'Token expired' &&
      err.response?.data?.message === 'Token expired'
    ) {
      if (type !== undefined) {
        throw new Error(ErrorMessageLinkExpired.CREATION_LINK_EXPIRED)
      }
      throw new Error(ErrorMessageLinkExpired.MODIFICATION_LINK_EXPIRED)
    } else if (err?.response?.data?.violations) {
      throw new Error(err.response.data.violations[0].message)
    } else {
      throw new Error("Une erreur est survenue lors de l'enregistrement")
    }
  }
  return status
}
