/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { IDecodedToken } from '../utils/interfaces/DecodedTokenInterface'
import { loginWithRefreshToken } from './connexion-services'
import { impersonnate } from './user-services'

export const checkTokenExpiry = async (emailImpersonated: string) => {
  const accessToken = localStorage.getItem('access_token')
  if (!accessToken) {
    return
  }
  // actualise le access_token et refresh_token si la date d'expiration du token actuel est proche(inférieur à 15 minutes)
  const decodAccessToken: IDecodedToken = jwt_decode(accessToken || '')
  if (decodAccessToken.exp - Date.now() / 1000 <= 15 * 60) {
    const refreshToken = localStorage.getItem('refresh_token')
    await loginWithRefreshToken(refreshToken || '')
  }
  // actualise l'impersonationToken et l'impersonation refresh_token si la date d'expiration du token actuel est proche(inférieur à 10 minutes)
  if (emailImpersonated !== '') {
    const impersonationToken = sessionStorage.getItem('impersonation_token')
    const decodImpersonationToken: IDecodedToken = jwt_decode(
      impersonationToken || ''
    )

    if (
      decodImpersonationToken.exp - Date.now() / 1000 <= 10 * 60 &&
      accessToken
    ) {
      axios.interceptors.request.clear()
      axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      }
      await impersonnate(emailImpersonated)
    }
  }
}

export default async function interceptorImpersonnation(axiosInterceptor: any) {
  // Nettoyer les interceptors
  axios.interceptors.request.clear()

  axiosInterceptor = axios.interceptors.request.use(
    (config: any) => {
      if (config.url && config.url.endsWith('/impersonate')) {
        /* eslint-disable-next-line no-param-reassign */
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          'access_token'
        )}`
        return config
      }
      const impersonationToken = sessionStorage.getItem('impersonation_token')
      if (impersonationToken !== null) {
        /* eslint-disable-next-line no-param-reassign */
        config.headers.Authorization = `Bearer ${impersonationToken}`
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )
}

export async function interceptorNavigation(axiosInterceptor: any) {
  axiosInterceptor = axios.interceptors.request.use(
    async (config: any) => {
      const accessToken = localStorage.getItem('access_token')
      const refreshToken = localStorage.getItem('refresh_token')

      if (
        config.url &&
        config.url.endsWith('/login') &&
        config.method === 'post'
      ) {
        return config
      }

      if (config.method === 'patch') {
        /* eslint-disable-next-line no-param-reassign */
        config.headers['Content-Type'] = 'application/merge-patch+json'
      }
      if (accessToken !== null && refreshToken != null) {
        /* eslint-disable-next-line no-param-reassign */
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          'access_token'
        )}`
      } else {
        axios.interceptors.request.eject(axiosInterceptor)
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )
}
